* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: #fff;
    color: #000;
    font-size: 15px;
    line-height: 1.5;
    justify-content: center;
    align-items: center;
}

.page_container {
    position: relative;
    min-height: 100vh;
}

.content_wrap {
    padding-bottom: 2.5rem;
    /* Footer height */
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    /* Footer height */
}

.nav_bar {
    width: 90%;
    margin: auto;
}

a {
    color: #262626;
    text-decoration: none;
}

ul {
    list-style: none;
}

.container {
    width: 90%;
    max-width: 1100px;
    margin: auto;
}

.container_fluid {
    width: 100% !important;
}

/* Nav */

.main-nav {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding-top: 8px;
    font-size: 20px;
    margin-top: 10px;
    background-image: linear-gradient(white, rgb(167, 164, 164));
    border-radius: 10px;
    color: rgb(255, 250, 250);
    width: 100%;
}

.main-nav .logo {
    margin-top: -10px;
    width: 110px;
    height: 60px;
    align-self: center
}

.main-nav ul {
    display: flex;
}

.main-nav ul li {
    padding: 0 0px;
    margin-top: 10px;
}

.main-nav ul li a {
    padding-bottom: 2px;
    color: rgb(0, 0, 0);
    padding-right: 20px;
    font-size: larger;
}

.main-nav ul li a:hover {
    color: #000;
    /* border: 1px solid rgb(167, 164, 164); */
    padding: 6px;
    border-radius: 5px;
    font-weight: 1000;
    font-size: 22px;
}

.main-nav ul.main-menu {
    z-index: 10;
    flex: 1;
    margin-left: 20px;
}

.menu-btn {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 30px;
    z-index: 2;
    display: none;
}

.btn {
    cursor: pointer;
    display: inline-block;
    border: 0;
    font-weight: bold;
    padding: 10px 20px;
    background: #262626;
    color: #fff;
    font-size: 20px;
    ;
}

.btn:hover {
    opacity: 0.9;
}

.dark {
    color: #fff;
}

.dark .btn {
    background: #f4f4f4;
    color: #333;
}

/* Showcase */

.showcase {
    width: 100%;
    height: 400px;
    background: url('https://i.ibb.co/zGSDGCL/slide1.png') no-repeat center center/cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    padding-bottom: 50px;
    margin-bottom: 20px;
}

.showcase h2,
.showcase p {
    margin-bottom: 10px;
}

.showcase .btn {
    margin-top: 20px;
}

/* Home cards */

.home-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-bottom: 40px;
}

.home-cards img {
    width: 100%;
    margin-bottom: 20px;
}

.home-cards h3 {
    margin-bottom: 5px;
}

.home-cards a {
    display: inline-block;
    padding-top: 10px;
    color: #0067b8;
    text-transform: uppercase;
    font-weight: bold;
}

.home-cards a:hover i {
    margin-left: 10px;
}

/* Xbox */

.xbox {
    width: 100%;
    height: 350px;
    background: url('https://i.ibb.co/tBJGPD9/xbox.png') no-repeat center center/cover;
    margin-bottom: 20px;
}

.xbox .content {
    width: 40%;
    padding: 50px 0 0 30px;
}

.xbox p,
.carbon p {
    margin: 10px 0 20px;
}

/* Carbon */

.carbon {
    width: 100%;
    height: 350px;
    background: url('https://i.ibb.co/72cgtsz/carbon.jpg') no-repeat center center/cover;
}

.carbon .content {
    width: 55%;
    padding: 100px 0 0 30px;
}

/* Follow */

.follow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 30px 0 30px;
}

.follow * {
    margin-right: 10px;
}

/* Links */

.links {
    background: #f2f2f2;
    color: #616161;
    font-size: 12px;
    padding: 35px 0;
}

.links-inner {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;
    align-items: flex-start;
    justify-content: center;
}

.links li {
    line-height: 2.8;
}

/* Footer */

.footer {
    background: #f2f2f2;
    justify-content: center;
    color: #616161;
    font-size: 12px;
    padding: 20px 0;
}

.footer-inner {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer div {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.footer div i {
    margin-right: 10px;
}

.footer ul {
    display: flex;
    flex-wrap: wrap;
}

.footer li {
    margin-right: 30px;
    margin-bottom: 20px;
}

@media(max-width: 1000px) {
    .menu-btn {
        display: block;
        margin-right: 3%;
        margin-top: 2%;
        font-size: 20px;
    }

    .menu-btn:hover {
        opacity: 0.5;
    }

    .main-nav ul.right-menu {
        margin-right: 50px;
    }

    .main-nav ul.main-menu {
        display: block;
        position: absolute;
        top: 0;
        left: 0px;
        background-image: linear-gradient(to right, white, gray);
        width: 50%;
        height: 100%;
        border-right: #ccc 1px solid;
        opacity: 0.9;
        padding: 30px;
        transform: translateX(-500px);
        transition: transform 0.5s ease-in-out;
    }

    .main-nav ul.main-menu li {
        padding: 10px;
        width: 190px;
        border-bottom: #ccc solid 1px;
        font-size: 18px;
    }

    .main-nav ul.main-menu li:last-child {
        border-bottom: 0;
    }

    .main-nav ul.main-menu.show {
        transform: translateX(-20px);
    }

    .home-cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .xbox .content p {
        display: none;
    }

    .xbox .content h2 {
        margin-bottom: 20px;
    }

    .carbon .content {
        width: 85%;
    }

    .links .links-inner {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(max-width: 500px) {
    .home-cards {
        grid-template-columns: 1fr;
    }

    .links .links-inner {
        grid-template-columns: 1fr;
    }

    .links .links-inner ul {
        margin-bottom: 20px;
    }
}

.title {
    background-image: linear-gradient(rgb(167, 164, 164), white, rgb(167, 164, 164));
    color: #333;
    padding: 5px;
    border-radius: 10px;
}

.title:hover {
    font-weight: 1000;
}

.assigmnt_btn {
    background-color: #262626;
    color: #f2f2f2;
    font-size: 15px;
    border-radius: 17px;
    padding: 5px;
}

.assigmnt_btn:hover {
    background-color: #f2f2f2;
    color: #262626;
}

.card {
    background-image: linear-gradient(to right, rgb(167, 164, 164), white, rgb(167, 164, 164));
}


.princple-msg {
    color: rgb(197, 199, 199);
    padding: 15px;
    text-align: justify;
}

.princple-msg p {
    font-size: 18px;
    font-family: 'Playfair Display', serif;
    text-align: justify;

}

.princple-msg h1 {

    width: fit-content;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 10px;
    align-self: center;
    color: rgb(197, 199, 199);
    font-weight: 700;
    font-family: 'Playfair Display', serif;
}

.anim {
    position: relative;
    align-items: center;
    margin-top: -4em;
    margin-bottom: 2em;
}

.anim h2 {
    text-transform: uppercase;
    font-size: 3em;
    font-weight: bolder;
    letter-spacing: 4px;
    overflow: hidden;
    background: linear-gradient(90deg, #203354, rgb(194, 194, 194),#203354);
    background-repeat: no-repeat;
    background-size: 90%;
    animation: animate 10s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(6, 0, 83, 0);
}

@keyframes animate {
    0% {
        background-position: -500%;
    }

    100% {
        background-position: 500%;
    }
}

.profile {
    align-items: center;
    text-align: center;
    font-size: 20px;
    color: rgb(223, 223, 223);
    padding-bottom: 2em;
    font-weight: 500;
    margin-bottom: 20px;
    font-family: 'Playfair Display', serif;
}

.profile h1 {
    /* background-image: linear-gradient(rgb(167, 164, 164), white, rgb(167, 164, 164)); */
    width: fit-content;
    border-radius: 18px;
    padding-left: 10px;
    padding-right: 10px;
    color: rgb(223, 223, 223);
    font-family: 'Playfair Display', serif;
}

/* .histry{
    color: rgb(197, 199, 199);
    padding: 15px;
    align-items: center;
    
}

.histry p{
    font-size: 18px;
    padding-top: 10px;
    font-family: 'Playfair Display', serif;
    text-align: justify;
}

.histry h1{
    width: fit-content;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 10px;
    align-self: center;
    color: rgb(197, 199, 199);
    font-weight: bolder;
    font-family: 'Playfair Display', serif;
} */

.basicinfo {
    align-items: center;
    text-align: justify;
    font-size: 20px;
    color: rgb(197, 199, 199);
    padding-bottom: 2em;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: 'Playfair Display', serif;
}

.basicinfo h2 {
    font-family: 'Playfair Display', serif;
    border-bottom: #ccc solid 1px;
    width: fit-content;
}

.basicinfo h1 {
    background-image: linear-gradient(rgb(167, 164, 164), white, rgb(167, 164, 164));
    width: fit-content;
    border-radius: 18px;
    padding-left: 10px;
    padding-right: 10px;
    color: #333;
    font-family: 'Playfair Display', serif;
    text-align: center;
    align-self: center;
}

.markup-aboutus {
    color: rgb(197, 199, 199);
}

.markup-aboutus li {
    margin-bottom: 2em;

}

.markup-aboutus li a {
    text-decoration: none;
    color: rgb(197, 199, 199);
    background-image: linear-gradient(to right, rgb(11, 3, 73), rgb(104, 95, 136), rgb(11, 3, 73));
    padding: 5px;
    border-radius: 20px;
}

.markup-aboutus li a:hover {
    border: outset 3px rgb(29, 27, 126);
    color: white;
    background-image: linear-gradient(to right, rgb(11, 3, 73), rgb(104, 95, 136), rgb(11, 3, 73));
    margin-bottom: 2em;
    padding: 5px;
    border-radius: 50px;
}

.histry {
    margin-bottom: 2em;
    font-size: 25px;
}

/* as */

.histry a {
    text-decoration: none;
    color: rgb(197, 199, 199);
    background-image: linear-gradient(to right, rgb(11, 3, 73), rgb(104, 95, 136), rgb(11, 3, 73));
    padding: 5px;
    border-radius: 20px;
}

.histry a:hover {
    border: outset 3px rgb(29, 27, 126);
    color: white;
    background-image: linear-gradient(to right, rgb(11, 3, 73), rgb(104, 95, 136), rgb(11, 3, 73));
    border-radius: 23px;
}

.contact-info {
    color: rgb(197, 199, 199);
    padding-bottom: 2em;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: 'Playfair Display', serif;
    text-align: justify;
    font-size: 20px;
}

.contact-info h1 {
    font-weight: bolder;
    font-family: 'Playfair Display', serif;
    font-size: 35px;
    border-bottom: #ccc solid 3px;
    margin-bottom: 1em;
    align-self: start;
}

.contact-info h2 {
    font-family: 'Playfair Display', serif;
}

.contactus {
    background-image: linear-gradient(rgb(167, 164, 164), white, rgb(167, 164, 164));
    width: fit-content;
    border-radius: 18px;
    padding-left: 10px;
    padding-right: 10px;
    color: #333;
    font-family: 'Playfair Display', serif;
    margin-bottom: 2em;
}

.nav_bar-admin a {
    text-decoration: none;
    color: rgb(10, 10, 10);
    margin-top: 2em;
    font-family: 'Playfair Display', serif;
    background-image: linear-gradient(rgb(167, 164, 164), white, rgb(167, 164, 164));
    padding: 10px;
    border-radius: 20px;
    font-weight: 600;
}

.nav_bar-admin a:hover {
    border: outset 3px rgb(168, 168, 168);
    font-weight: 900;
    color: #000;
}

#clubs {
    background-image: linear-gradient(rgb(167, 164, 164), white, rgb(167, 164, 164));
    border-radius: 18px;
    color: #000;

}