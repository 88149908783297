.history_message{
    font-size: 18px;
    padding-top: 10px;
    font-family: 'Playfair Display', serif;
    text-align: justify;
    color: rgb(197, 199, 199);
}

.history_message h1{
   
    padding-left: 0px;
    padding-right: 0px;
   
    color: rgb(197, 199, 199);
    font-weight: bolder;
    font-family: 'Playfair Display', serif;
    font-size: 30px;
    border-bottom: #ccc solid 3px;
    margin-bottom: 1em;
}

.principles{
    color: rgb(197, 199, 199);
    font-weight: bolder;
    font-family: 'Playfair Display', serif;
    font-size: 30px;
    margin-top: 20px;
    padding-top: 2em;
}