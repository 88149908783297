.navLinksA {
    text-decoration: none;
}

nav {
    font-family: 'Playfair Display', serif;
}

.dropdown li{
    list-style: none;
    margin-bottom: 10px;
    margin-left: 5px;
    padding-left: 5px;
    font-size: 12px;
    border-radius: 10px;
    padding: 10px;
    width: max-content;
    
}

.navLinks {
    color: #fff;
    display: block;
    float: left;
    padding: 1rem;
    position: relative;
    text-decoration: none;
    transition-duration: 0.5s;
}



.navLinks:hover {
    cursor: pointer;
}

ul li ul {
    z-index: 100;
    background-image: linear-gradient(to right,rgba(255, 255, 255), rgba(167, 164, 164));
    visibility: hidden;
    opacity: 0;
    min-width: 5rem;
    position: absolute;
    transition: all 0.5s ease;
    margin-top: 1rem;
    left: 0;
    display: none;
    border-radius: 10px;
}

ul li:hover>ul,
ul li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
}

ul li ul li {
    clear: both;
    width: 100%;
}