.anthem {
    width: 60vw !important;
    height: 500px !important;
}

@media(max-width: 900px) {
    .anthem {
        height: 400px !important;
    }
}

@media(max-width: 700px) {
    .anthem {
        height: 300px !important;
    }
}

@media(max-width: 500px) {
    .anthem {
        height: 200px !important;
    }
}