.dropper {
    margin-top: 20px;
    height: 30vh;
    border: 2px dashed black;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.image_upload {
    width: 100%;
    height: 30vh;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.dropper:hover {
    background: #eee;
}

.image_div {
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    justify-content: space-between;
}

.image_div img {
    width: 100px;
}