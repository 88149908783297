/* body {
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  font-family: sans-serif;
}

.login {
  width: 320px;
  height: 420px;
  background: #000;
  color: #fff;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 70px 30px;
}

.login_icon {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  position: absolute;
  top: -50px;
  left: calc(50% - 50px);
}

h1 {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 22px;
}

.login p {
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.login input {
  width: 100%;
  margin-bottom: 20px;
}

.input_text {
  border: none !important;
  border-bottom: 1px solid #fff !important;
  background: transparent !important;
  outline: none !important;
  height: 40px !important;
  color: #fff !important;
  font-size: 16px !important;
}

.login_button {
  text-align: center;
  border: none;
  outline: none;
  height: 30px;
  width: 60px;
  background: #fb2525;
  color: #fff;
  border-radius: 20px;
}

.login_button:hover {
  cursor: pointer;
  background: #ffc107;
  color: #000;
}

.login a {
  text-decoration: none;
  font-size: 12px;
  line-height: 20px;
  color: darkgrey;
}

.login a:hover {
  color: green;
} */


.title {
  color: white;
}