.flag_image {
    width: 600px !important;
    margin: auto;
}

@media(max-width: 1000px) {
    .flag_image {
        width: 500px !important;
    }
}

@media(max-width: 900px) {
    .flag_image {
        width: 450px !important;
    }
}

@media(max-width: 700px) {
    .flag_image {
        width: 450px !important;
    }
}

@media(max-width: 500px) {
    .flag_image {
        width: 300px !important;
    }
}

@media(max-width: 300px) {
    .flag_image {
        width: 150px !important;
    }
}